import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FoodStall } from '../../models/food-stall.model';
import { defaultBgColor, defaultLogoUrl } from '../../data';
import { zBounceInfinite } from '../../animations/animations';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  animations: [
    zBounceInfinite
  ]
})
export class SplashScreenComponent implements OnInit {
  defaultLogo: string = defaultLogoUrl;
  defaultBgColor: string = defaultBgColor;
  fullyLoaded: boolean = false;
  bounceTrigger: boolean;
  @Input() foodStall: FoodStall = new FoodStall();
  @Output('fullyLoaded') isLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    setInterval(() => {
      this.bounceTrigger = !this.bounceTrigger
    }, 2500);
  }

  onLoad(): void {
    this.fullyLoaded = true;
    this.isLoaded.emit(this.fullyLoaded);
  }

  onError(): void {
    this.foodStall.logoUrl = defaultLogoUrl;
  }
}

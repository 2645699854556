import { Component, EventEmitter, Input, Output } from '@angular/core';
import { bounceInUp } from '../../animations/animations';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [
    bounceInUp
  ]
})
export class MainNavComponent {
  @Input() bgColor!: string;
  @Output('isVegOnlyEnabled') isVegOnlyEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

  toggle: boolean = false;
  searchTerm: string;
  timeout: any = null;

  constructor() { }

  onVegOnlyBtnClick(): void {
    this.toggle = !this.toggle;
    this.isVegOnlyEnabled.emit(this.toggle);
  }

  onSearchEvent(): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onSearch.emit(this.searchTerm);
    }, 500);
  }

  clearEvent(): void {
    this.searchTerm = '';
    this.onSearchEvent();
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Observable, tap } from 'rxjs';

@Injectable()
export class NetService {

  public static readonly MAIN_TOKEN_HEADER_NAME = 'authorization';
  public static readonly REFRESH_TOKEN_HEADER_NAME = 'refresh-token';

  constructor(private httpClient: HttpClient, private storageService: StorageService) { }

  process(net: Net): Observable<HttpResponse<any>> {
    this.fillHeader(net);
    let request: any;
    switch (net.method) {
      case 'get':
        request = this._get(net);
        break;
      case 'post':
        request = this._post(net);
        break;
      case 'put':
        request = this._put(net);
        break;
      case 'delete':
        request = this._delete(net);
        break;
      case 'head':
        request = this._head(net);
    }

    return request.pipe(tap((response) => {
      net.response = response;
      this.saveHeader(net);
      return response;
    }));
  }

  public static addAuthorizationTokensToUrl(url: string): string {
    const authToken = StorageService.getAuthorizationToken();
    if (!authToken) {
      return url;
    }

    let params = new URLSearchParams();
    params.append("_" + NetService.MAIN_TOKEN_HEADER_NAME.toLowerCase(), authToken);

    return `${url}?${params}`;
  }

  private _get(net: Net) {
    return this.httpClient.get<HttpResponse<any>>(net.url, net.getObject());
  }

  private _post(net: Net) {
    return this.httpClient.post<HttpResponse<any>>(net.url, net.body, net.getObject());
  }

  private _put(net: Net) {
    return this.httpClient.put<HttpResponse<any>>(net.url, net.body, net.getObject());
  }

  private _delete(net: Net) {
    let obj: any = net.getObject();
    obj.body = net.body;
    return this.httpClient.delete<HttpResponse<any>>(net.url, obj);
  }

  private _head(net: Net) {
    return this.httpClient.head<HttpResponse<any>>(net.url, net.getObject());
  }

  private saveHeader(net: Net) {
    let authToken = net.response.headers.get(NetService.MAIN_TOKEN_HEADER_NAME);
    let refreshToken = net.response.headers.get(NetService.REFRESH_TOKEN_HEADER_NAME);

    if (authToken && net.tokenSaveKey) {
      this.storageService.saveToken(net.tokenSaveKey, authToken);
    }

    if (refreshToken && net.refreshTokenSaveKey) {
      this.storageService.saveToken(net.refreshTokenSaveKey, refreshToken);
    }
  }

  private fillHeader(net: Net) {
    let authToken = StorageService.getAuthorizationToken();
    if (authToken && net.tokenGetKey) {
      net.headers[NetService.MAIN_TOKEN_HEADER_NAME] = authToken;
    }

    net.headers['V-App-Version'] = 'todo';
    net.headers['V-App-Platform'] = 'web';
    net.headers['wt-device'] = 'web';
  }
}

export class Net {

  public static ERROR_HANDLING_HEADER_NAME = 'error_handler';
  public static CACHE_HANDLING_HEADER_NAME = 'cache_handler';

  public static readonly ERROR_HANDLER = {
    ONTHESPOT: 'on_the_spot',
    CENTRALIZED: 'centralized',
    BOTH: 'both'
  };

  method: string | undefined;
  private _url = "";
  body: any;
  headers: any = {};
  attempt = 0;
  response: any;
  meta: any;
  param: any = {};
  responseType = 'json';
  tokenGetKey: string = StorageService.DEFAULT_TOKEN_KEY;
  tokenSaveKey: string = StorageService.DEFAULT_TOKEN_KEY;
  refreshTokenSaveKey: string = StorageService.REFRESH_TOKEN_KEY;
  errorHandling: string = Net.ERROR_HANDLER.BOTH;
  cacheName = "";
  cacheMaxAge = 20; // maximum cache age in seconds;
  reportProgress = false;
  observe = 'response'; //'events';

  constructor(method?: string) {
    this.method = method;
  }

  setQueryParam(key: string, value: any) {
    this.param[key] = value;
    return this;
  }

  static head() {
    let n = new Net();
    n.method = 'head';
    return n;
  }

  static get() {
    let n = new Net();
    n.method = 'get';
    return n;
  }

  static post() {
    let n = new Net();
    n.method = 'post';
    return n;
  }

  static put() {
    let n = new Net();
    n.method = 'put';
    return n;
  }

  static delete() {
    let n = new Net();
    n.method = 'delete';
    return n;
  }

  getObject(): any {
    return {
      headers: this.addExtraHeaders(),
      params: this.param,
      observe: this.observe,
      responseType: this.responseType,
      reportProgress: this.reportProgress,
    };
  }

  private addExtraHeaders() {
    this.headers[Net.ERROR_HANDLING_HEADER_NAME] = this.errorHandling;
    this.headers[Net.CACHE_HANDLING_HEADER_NAME] = JSON.stringify({ name: this.cacheName, time: this.cacheMaxAge });
    return this.headers;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

}
import { Component, Input } from '@angular/core';
import { FoodStall } from '../../models/food-stall.model';
import { bounceInDown, bounceInUp } from '../../animations/animations';
import { defaultBgColor, defaultLogoUrl } from '../../data';

@Component({
  selector: 'app-fstall-details',
  templateUrl: './fstall-details.component.html',
  styleUrls: ['./fstall-details.component.scss'],
  animations: [
    bounceInUp,
    bounceInDown
  ]
})
export class FstallDetailsComponent {
  @Input() foodStallDetails: FoodStall = new FoodStall();
  @Input() isLoaded: boolean = false;
  defaultBgColor: string = defaultBgColor;
  defaultLogo: string = defaultLogoUrl;
  googleBusinessUrl: string;
  profile_url: string;

  constructor() { }

  navigateToWhatsApp(): void {
    window.open(this.foodStallDetails.whatsappUrl, '_blank');
  }

  navigateToGoogleBusiness(): void {
    if (this.foodStallDetails.googleBusinessUrl) {
      window.open(this.foodStallDetails.googleBusinessUrl, '_blank');
    }
  }

  navigateToProfile(): void {
    if (this.foodStallDetails.profileUrl) {
      window.open(this.foodStallDetails.profileUrl, '_blank');
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { FstallCardComponent } from './components/fstall-card/fstall-card.component';
import { FstallDetailsComponent } from './components/fstall-details/fstall-details.component';
import { FstallButtonsComponent } from './components/fstall-buttons/fstall-buttons.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { ChatComponent } from './components/chat/chat.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    MainNavComponent,
    FstallCardComponent,
    FstallDetailsComponent,
    FstallButtonsComponent,
    SplashScreenComponent,
    ChatComponent,
    RatingStarsComponent,
    SafeResourceUrlPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    FormsModule
  ],
  exports: [
    MainNavComponent,
    FstallCardComponent,
    FstallDetailsComponent,
    FstallButtonsComponent,
    SplashScreenComponent,
    ChatComponent,
    RatingStarsComponent,
    SafeResourceUrlPipe
  ]
})
export class SharedModule { }

export class Category {
    private _id: string;
    private _name: string;
    private _status: string;

    constructor(json?: any) {
        if (json) {
            this._id = json.id;
            this._name = json.name;
            this._status = json.status;
        }
    }

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get status(): string {
        return this._status;
    }

    public set status(value: string) {
        this._status = value;
    }
}

import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Food } from 'src/app/shared/models/food.model';

@Component({
  selector: 'app-fstall-food-card',
  templateUrl: './fstall-food-card.component.html',
  styleUrls: ['./fstall-food-card.component.scss']
})
export class FstallFoodCardComponent implements OnInit {
  @Input() foodItem: Food;
  @Input() autoplay: boolean = false;
  stallId: string;
  qrVersion: string;
  tableId: string;
  queryParams: any;
  finalFoodDetailsUrl: string;
  finalVideoUrl: string;
  isHovered: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.stallId = params['stallId'];
      this.qrVersion = params['qrVersion'];
      this.tableId = params['tableId'];

      if (this.qrVersion && this.tableId) {
        this.queryParams = {
          v: this.qrVersion,
          table: this.tableId
        }
      }

      this.finalFoodDetailsUrl = `/stall/${this.stallId}/food/${this.foodItem.id}`;
    })

    setTimeout(() => {
      this.finalVideoUrl = this.foodItem.primaryVideoURL;
    }, 800);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['autoplay'].currentValue == true) {

      setTimeout(() => {
        this.isHovered = true;
        this.finalVideoUrl = this.getFinalVideoUrl;
      }, 800);

    } else {
      this.isHovered = false;
    }
  }

  private get getFinalVideoUrl(): string {
    const finalVideoUrl: string = this.isHovered ? this.foodItem.primaryVideoURL.replace('autoplay=0', 'autoplay=1')
      : this.foodItem.primaryVideoURL.replace('autoplay=1', 'autoplay=0');

    return finalVideoUrl;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PathHelperService } from './services/helpers/path-helper.service';
import { NetService } from './services/network/net.service';
import { StorageService } from './services/storage/storage.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    PathHelperService,
    NetService,
    StorageService
  ]
})
export class CoreModule { }

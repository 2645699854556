import { animate, group, keyframes, query, state, style, transition, trigger } from "@angular/animations";

export let fadeInOut = trigger('fadeInOut', [
    state('hidden', style({ opacity: 0 })),
    state('visible', style({ opacity: 1 })),
    transition('hidden <=> visible', animate('0.25s ease-in-out'))
]);

export let bounceInUp = trigger('bounceInUp', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate('0.8s', keyframes([
            style({
                opacity: 0,
                transform: 'translateY(400px)',
                offset: 0
            }),
            style({
                opacity: 1,
                transform: 'translateY(-10px)',
                offset: 0.60
            }),
            style({
                opacity: 1,
                transform: 'translateY(5px)',
                offset: 0.75
            }),
            style({
                transform: 'translateY(0)',
                offset: 1
            }),
        ]))
    ])
])

export let bounceInDown = trigger('bounceInDown', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate('0.8s', keyframes([
            style({
                opacity: 0,
                transform: 'translateY(-400px)',
                offset: 0
            }),
            style({
                opacity: 1,
                transform: 'translateY(10px)',
                offset: 0.60
            }),
            style({
                opacity: 1,
                transform: 'translateY(-5px)',
                offset: 0.75
            }),
            style({
                transform: 'translateY(0)',
                offset: 1
            }),
        ]))
    ])
])

export let slideInOut = trigger('routeAnimation', [
    transition('isLeft => isRight', horizontalSlide('right')),
    transition('isRight => isLeft', horizontalSlide('left')),
    transition('isRight => isBottom', verticalSlide('bottom')),
    transition('isBottom => isRight', verticalSlide('top')),
    transition('isLeft => isBottom', verticalSlide('bottom')),
    transition('isBottom => isLeft', verticalSlide('top'))
]);

export let bounceInfinite = trigger('bounceInfinite', [
    transition('* => *', [
        animate(
            500,
            keyframes([
                style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
                style({ transform: 'translate3d(0, -20px, 0) scaleY(1.1)', offset: 0.20 }),
                style({ transform: 'translate3d(0, -10px, 0) scaleY(1.05)', offset: 0.50 }),
                style({ transform: 'translate3d(0, 0, 0) scaleY(0.95)', offset: 0.70 }),
                style({ transform: 'translate3d(0, -4px, 0) scaleY(1.02)', offset: 0.89 }),
                style({ transform: 'translate3d(0, 0, 0)', offset: 1.0 })
            ])
        ),
    ]),
])

export let zBounceInfinite = trigger('zBounceInfinite', [
    transition('* => *', [
        animate(
            500,
            keyframes([
                style({ transform: '', offset: 0 }),
                style({ transform: 'scale(1.1)', offset: 0.20 }),
                style({ transform: 'scale(1.05)', offset: 0.50 }),
                style({ transform: 'scale(0.95)', offset: 0.70 }),
                style({ transform: 'scale(1.02)', offset: 0.89 }),
                style({ transform: '', offset: 1.0 })
            ])
        ),
    ]),
])

function horizontalSlide(direction: string) {
    const optional = { optional: true };
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                [direction]: 0,
                width: '100%',
            })
        ], optional),
        query(':enter', [
            style({ [direction]: '-100%' })
        ]),
        group([
            query(':leave', [
                animate('0.75s ease', style({ [direction]: '100%', zIndex: -1 }))
            ], optional),
            query(':enter', [
                animate('0.75s ease', style({ [direction]: '0%', zIndex: 999 }))
            ])
        ])
    ];
}

function verticalSlide(direction: string) {
    const optional = { optional: true };
    return [
        query(':enter, :leave', [
            style({
                position: 'relative',
                [direction]: 0,
                left: 0,
                width: '100%',
            })
        ], optional),
        query(':enter', [
            style({ [direction]: '-100%' })
        ]),
        group([
            query(':leave', [
                animate('0.5s ease', style({ [direction]: '100%', zIndex: -1 }))
            ], optional),
            query(':enter', [
                animate('0.5s ease', style({ [direction]: '0%', zIndex: 999 }))
            ])
        ])
    ];
}
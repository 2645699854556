import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { AccessGuard } from './core/guards/access.guard';
import { InvalidLinkComponent } from './layout/invalid-link/invalid-link.component';
import { FstallDashboardComponent } from './feature/fstall-dashboard/fstall-dashboard.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'page-not-found'
  },
  {
    path: 'stall/:stallId/:qrVersion/OUTLET/:tableId',
    loadChildren: () => import('./feature/fstall-dashboard/fstall-dashboard.module').then(
      m => m.FstallDashboardModule
    ),
    data: { animation: 'isLeft' },
    canActivate: [AccessGuard]
  },
  {
    path: 'stall/:stallId/food/:foodId',
    loadChildren: () => import('./feature/food-page/food-page.module').then(
      m => m.FoodPageModule
    ),
    data: { animation: 'isRight' },
    canActivate: [AccessGuard]
  },
  {
    path: 'call-for-service',
    loadChildren: () => import('./feature/call-for-service/call-for-service.module').then(
      m => m.CallForServiceModule
    ),
    data: { animation: 'isBottom' }
  },
  {
    path: 'stall/:stallId',
    component: FstallDashboardComponent,
    title: 'FoodieBell',
    data: { animation: 'isLeft' },
    canActivate: [AccessGuard]
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    title: 'FoodieBell: 404 Not Found'
  },
  {
    path: 'invalid-link',
    component: InvalidLinkComponent,
    title: 'FoodieBell: Invalid Link'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { tagList } from "../data";
import { OptionalVideo } from "./optional-video.model";

export enum FoodType {
    VEG = 'Veg',
    NONVEG = 'Non-Veg'
}

export enum EmbedVideoType {
    YTSHORT = 'YTSHORT',
    INSTAREEL = "INSTAREEL",
    TIKTOK = "TIKTOK"
}

export class Food {
    private _id: string;
    private _name: string;
    private _outletCategoryId: string;
    private _tags: string;
    private _price: string;
    private _type: string;
    private _description: string;
    private _videoLinks: any;
    private _status: string;
    private _tagsAsArray: string[];
    private _tagsList: any[] = tagList;

    //custom props
    private _primaryVideoType: string;
    private _primaryVideoId: string;
    private _optionalVideos: OptionalVideo[];
    private _primaryVideoThumbnail: string;

    constructor(json?: any) {
        if (json) {
            this._id = json.id;
            this._name = json.name;
            this._outletCategoryId = json.outlet_category_id;
            this._tags = json.tags;
            this._price = json.price;
            this._type = json.type;
            this._description = json.description;
            this._videoLinks = json.video_links;
            this._status = json.status;
        }
    }

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get outletCategoryId(): string {
        return this._outletCategoryId;
    }

    public set outletCategoryId(value: string) {
        this._outletCategoryId = value;
    }

    public get tags(): string {
        return this._tags;
    }

    public set tags(value: string) {
        this._tags = value;
    }

    public get price(): string {
        return this._price;
    }

    public set price(value: string) {
        this._price = value;
    }

    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        this._type = value;
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        this._description = value;
    }

    public get videoLinks(): any {
        return this._videoLinks;
    }

    public set videoLinks(value: any) {
        this._videoLinks = value;
    }

    public get primaryVideoType(): string {
        this._primaryVideoType = this.videoLinks.primary_video_type;
        return this._primaryVideoType;
    }

    public get primaryVideoId(): string {
        this._primaryVideoId = this.videoLinks.primary_video_id;
        return this._primaryVideoId;
    }

    public get optionalVideos(): OptionalVideo[] {
        if (this.videoLinks.optional_videos) {
            this._optionalVideos = this.videoLinks.optional_videos.map(
                (video: any) => new OptionalVideo(video)
            );
        }
        return this._optionalVideos;
    }

    public get status(): string {
        return this._status;
    }

    public set status(value: string) {
        this._status = value;
    }

    public get tagsAsArray(): any[] {
        let finalTagsList: any[];
        this._tagsAsArray = this.tags.split(',');
        finalTagsList = this._tagsList.filter((tag) => this._tagsAsArray.includes(tag.value));

        return finalTagsList;
    }

    public get primaryVideoURL(): string {
        return `https://www.youtube.com/embed/${this.primaryVideoId}?autoplay=1&loop=1&controls=0&mute=1&playlist=${this.primaryVideoId}`;
    }

    public get primaryVideoThumbnail(): string {
        if (this.primaryVideoId) {
            this._primaryVideoThumbnail = `https://img.youtube.com/vi/${this._primaryVideoId}/maxresdefault.jpg`;
        }
        return this._primaryVideoThumbnail;
    }
}
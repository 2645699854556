import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, finalize, map, throwError } from 'rxjs';
import { FoodStall } from '../models/food-stall.model';
import { Net, NetService } from 'src/app/core/services/network/net.service';
import { PathHelperService } from 'src/app/core/services/helpers/path-helper.service';
import { Logger, LoggerFactory } from 'src/app/core/utilities/logger/logger';
import { Category } from '../models/category.model';
import { Food } from '../models/food.model';
import { LoaderService } from 'src/app/library/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class FStallService {

  private logger: Logger = LoggerFactory.getLogger(FStallService);

  constructor(
    private netService: NetService,
    private pathHelperService: PathHelperService,
    private loader: LoaderService
  ) { }

  getFoodStallDetails(id: string): Observable<FoodStall> {
    const qParam: string = `user_outlet_id=${id}`;

    let net = Net.get();
    net.url = this.pathHelperService.getApiURL('get-outlet-details', qParam);

    return this.netService.process(net).pipe(map((res) => {
      return res.body;
    }), catchError((error) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }

  getCategories(id: string): Observable<Category[]> {
    const qParam: string = `user_outlet_id=${id}`;

    let net = Net.get();
    net.url = this.pathHelperService.getApiURL('get-outlet-categories', qParam);

    return this.netService.process(net).pipe(map((res) => {
      return res.body;
    }), catchError((error) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }

  getFoodItems(data: any): Observable<Food[]> {
    const qParam: string = `page=${data.pageNo}&user_outlet_id=${data.id}`;

    let net = Net.get();
    net.url = this.pathHelperService.getApiURL('get-outlet-foods', qParam);

    return this.netService.process(net).pipe(map((res) => {
      return res.body;
    }), catchError((error) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }

  getNextPageFoodItems(data: any): Observable<Food[]> {
    const qParam: string = `page=${data.pageNo}&user_outlet_id=${data.id}`;

    let net = Net.get();
    net.url = this.pathHelperService.getApiURL('get-outlet-foods', qParam);

    return this.netService.process(net).pipe(map((res) => {
      return res.body;
    }), catchError((error) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }

  filterFoodItems(data: any): Observable<Food[]> {
    let qParam!: string;

    if (data.stallId) {
      qParam = `user_outlet_id=${data.stallId}&page=${data.pageNo}`;

      if (data.searchTerm) {
        qParam += `&name=${data.searchTerm}`;

        if (data.category && data.type) {
          qParam += `&outlet_category_id=${data.category}&type=${data.type}`;
        } else if (data.category) {
          qParam += `&outlet_category_id=${data.category}`;
        } else if (data.type) {
          qParam += `&type=${data.type}`;
        }
      } else {
        if (data.category && data.type) {
          qParam += `&outlet_category_id=${data.category}&type=${data.type}`;
        } else if (data.category) {
          qParam += `&outlet_category_id=${data.category}`;
        } else if (data.type) {
          qParam += `&type=${data.type}`;
        }
      }
    }

    let net = Net.get();
    net.url = this.pathHelperService.getApiURL('get-outlet-foods', qParam);

    return this.netService.process(net).pipe(map((res) => {
      return res.body;
    }), catchError((error) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }

  getSingleFoodItem(data: any): Observable<Food> {
    let qParam: string = '';

    if (data.stallId && data.foodId) {
      qParam = `user_outlet_id=${data.stallId}&id=${data.foodId}`;
    }

    let net = Net.get();
    net.url = this.pathHelperService.getApiURL('get-outlet-foods', qParam);

    return this.netService.process(net).pipe(map((res) => {
      return res.body;
    }), catchError((error) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }
}

export const environment = {
    production: false,

    pusherConfig: {
        broadcaster: 'pusher',
        key: '6105c87faa80232b73f1',
        cluster: 'ap2',
    }
};

export function getServiceUrl() {
    console.log('get service url dev');
    return 'https://foodie-dev-api.treinetic.xyz/api/v1.0';
}
<div class="fullPage" *ngIf="!!foodStall">
    <!-- <div #splashScreen [@fadeInOut]="fullyLoaded ? 'visible' : 'hidden'">
        <app-splash-screen [foodStall]="foodStall" (fullyLoaded)="onImageLoad($event)"></app-splash-screen>
    </div> -->

    <div class="scrollable-container" infinite-scroll [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollThrottle]="scrollThrottle" [scrollWindow]="false" (scrolled)="onScrollDown()">
        <app-fstall-details [foodStallDetails]="foodStall" [isLoaded]="isLoaded"></app-fstall-details>

        <div class="navbar-container" *ngIf="isLoaded" @bounceInDown>
            <app-main-nav [bgColor]="bgColor" (isVegOnlyEnabled)="onVegOnlyBtnClick($event)"
                (onSearch)="onSearch($event)"></app-main-nav>
        </div>

        <app-fstall-buttons [isLoaded]="isLoaded" (selectedCategory)="onCategorySelect($event)"></app-fstall-buttons>

        <div class="wrapper" *ngIf="isLoaded" @bounceInUp>
            <ng-container *ngIf="foodItems.length; else noData">
                <div class="list-container">
                    <ng-container *ngFor="let foodItem of foodItems; index as i;">
                        <div class="list-item">
                            <app-fstall-food-card [foodItem]="foodItem"
                                [autoplay]="autoplayIndex === i ? true : false"></app-fstall-food-card>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #noData>
                <div class="nodata-container text-center">
                    <img src="../../../assets/images/empty_result.svg" alt="">
                    <p class="text-secondary"><b>No matching food items!</b></p>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<!-- New Version -->
<div class="fs-main" [style.background-color]="foodStallDetails.logoBgColor ? foodStallDetails.logoBgColor : '#384624'"
  *ngIf="isLoaded" @bounceInDown>
  <div class="details-container">
    <div class="logo">
      <div class="logo-cover"
        [style.background-color]="foodStallDetails.logoBgColor ? foodStallDetails.logoBgColor : defaultBgColor">
        <a class="link" [ngStyle]="{'cursor': foodStallDetails.profileUrl ? 'pointer' : 'default'}"
          (click)="navigateToProfile()">
          <img [src]="foodStallDetails.logoUrl" class="img-fluid">
        </a>
      </div>
    </div>

    <div class="details">
      <span class="fstall-name">{{ foodStallDetails.name }}</span>
      <span class="fstall-location">
        <i class="bx bxs-map me-1"></i>
        <span>{{ foodStallDetails.location }}</span>
      </span>
      <a class="link" [ngStyle]="{'cursor': foodStallDetails.googleBusinessUrl ? 'pointer' : 'default'}"
        (click)="navigateToGoogleBusiness()">
        <div class="google-review">
          <img src="./assets/images/google_review_logo.svg" alt="">
        </div>
      </a>
    </div>
    <div class="call">
      <button type="button" class="btn call-btn" (click)="navigateToWhatsApp()">
        <i class='bx bxl-whatsapp'></i>
      </button>
    </div>
  </div>
</div>
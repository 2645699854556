<div class="spinner-backdrop" *ngIf="showLoader">
    <table id="wrapper">
        <tr>
            <td>
                <div class="row justify-content-center">
                    <div class="col-md-3">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <svg class="spinner align-middle loader-cycle-center" width="65px" height="65px"
                                    viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33"
                                        cy="33" r="30">
                                    </circle>
                                </svg>
                            </div>
                            <div class="col-md-12 p-4 text-styling" *ngIf="message">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderEvent: EventEmitter<LoaderEvent> = new EventEmitter();
  active: boolean;

  constructor() { }

  public eventListener() {
    return this.loaderEvent;
  }

  public show(message?: string) {
    this.active = true;
    this.loaderEvent.emit({ show: this.active, msg: message });
  }

  public hide() {
    this.active = false;
    this.loaderEvent.emit({ show: this.active });
  }
}

interface LoaderEvent {
  show: boolean, msg?: string | undefined
};

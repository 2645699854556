<!-- New Version -->
<div class="fcard-container">
  <a [routerLink]="[finalFoodDetailsUrl]" [queryParams]="queryParams ? queryParams : ''">
    <div class="fcard">
      <!-- <div class="fcard-xp-badge">
      <span>1x</span>
    </div> -->
      <div class="fcard-media">
        <div class="fcard-thumbnail" *ngIf="!isHovered">
          <img [src]="foodItem.primaryVideoThumbnail">
        </div>
        <div class="fcard-video" *ngIf="isHovered">
          <iframe width="100%" height="100%" [src]="finalVideoUrl | safeResourceUrl" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="fcard-overlay">
        <div class="fcard-content">
          <span class="fcard-title">{{ foodItem.name }}</span>
          <span class="fcard-price">{{ foodItem.price | currency: 'AED ' }}</span>
        </div>
        <ng-container *ngIf="foodItem.tagsAsArray">
          <div class="fcard-badges">
            <ng-container *ngFor="let tag of foodItem.tagsAsArray">
              <div class="single-badge">
                <img [src]="tag.imageUrl" alt="">
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </a>
</div>
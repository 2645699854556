import { Component, Input } from '@angular/core';
import { FoodStall } from '../../models/food-stall.model';

@Component({
  selector: 'app-fstall-card',
  templateUrl: './fstall-card.component.html',
  styleUrls: ['./fstall-card.component.scss']
})
export class FstallCardComponent {

  @Input() foodStallCards: FoodStall[] = [];

}

import { Component, OnInit } from '@angular/core';
import { PusherService } from './core/services/pusher/pusher.service';
import { v4 as uuidv4 } from 'uuid';
import { StorageService } from './core/services/storage/storage.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'foodie-app-client';
  deviceId: string;

  constructor(
    private pusherService: PusherService,
    private storageService: StorageService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.setDeviceId(),
      this.listenToPusher()
    ]);
  }

  private setDeviceId(): void {
    if (!this.storageService.exists('deviceId')) {
      this.deviceId = uuidv4();
      this.storageService.setData('deviceId', this.deviceId);
    }
  }

  private listenToPusher(): void {
    this.pusherService.requestPermission().finally(() => {
      const deviceIdFromStorage = this.storageService.getData('deviceId');
      if (deviceIdFromStorage) {
        this.pusherService.subscribeToChannel(deviceIdFromStorage, (data) => { });
      }
    });
  }
}

export class FoodStall {
    private _id: string;
    private _name: string;
    private _location: string;
    private _contactNumber: string;
    private _logoUrl: string;
    private _logoBgColor: string;
    private _googleBusinessUrl: string;
    private _profileUrl: string;

    //custom props
    private _whatsappUrl: string;

    constructor(json?: any) {
        if (json) {
            this._id = json.id;
            this._name = json.user_outlets.franchise_name;
            this._location = json.address;
            this._contactNumber = json.user_outlets.contact_person_whatsapp;
            this._logoUrl = json.image_url.large;
            this._logoBgColor = json.user_outlets.theme_color;
            this._googleBusinessUrl = json.user_outlets.place_url;
            this._profileUrl = json.user_outlets.profile_url;
        }
    }

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get location(): string {
        return this._location;
    }

    public set location(value: string) {
        this._location = value;
    }

    public get contactNumber(): string {
        return this._contactNumber;
    }

    public set contactNumber(value: string) {
        this._contactNumber = value;
    }

    public get logoUrl(): string {
        return this._logoUrl;
    }

    public set logoUrl(value: string) {
        this._logoUrl = value;
    }

    public get logoBgColor(): string {
        return this._logoBgColor;
    }

    public set logoBgColor(value: string) {
        this._logoBgColor = value;
    }

    public get googleBusinessUrl(): string {
        return this._googleBusinessUrl;
    }

    public set googleBusinessUrl(value: string) {
        this._googleBusinessUrl = value;
    }

    public get profileUrl(): string {
        return this._profileUrl;
    }
    
    public set profileUrl(value: string) {
        this._profileUrl = value;
    }

    public get whatsappUrl(): string {
        this._whatsappUrl = `https://wa.me/${this.contactNumber}`;
        return this._whatsappUrl;
    }
}

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {

  message: any;
  showLoader = false;

  subscription: Subscription = new Subscription();

  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.eventListener().subscribe((event) => {
      const message = event.msg;
      event.show ? this.show(message) : this.hide();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public show(messgae?: string) {
    this.message = messgae;
    this.showLoader = true;
  }

  public hide() {
    this.message = null;
    this.showLoader = false;
  }

}
import { Injectable } from '@angular/core';
import { getServiceUrl } from 'src/environments/environment';
import api_endpoints from './api_endpoints.json';

@Injectable()
export class PathHelperService {
  public static PATH_KEY = {
    "REFRESH_TOKEN": "refresh-token"
  }

  private api_url: string;

  endPoints = api_endpoints;
  keys: string[] = Object.keys(this.endPoints);
  urls: string[] = Object.values(this.endPoints);

  constructor() {
    this.api_url = getServiceUrl();
  }

  public createFullURL(path: string) {
    return this.api_url + path;
  }

  getApiURL(key: string, param?: string[] | string): string {
    let url;
    let params: string[] = [];

    if (param) {
      if (!Array.isArray(param)) {
        params.push(param);
      } else {
        params = param;
      }
    }

    if (!this.keys.includes(key)) {
      const err = 'Invalid key, No records for key: ' + key;
      throw new Error(err);
    }

    let index = this.keys.indexOf(key);
    url = this.urls[index];
    url = url.split(' ').join('');

    this.validateInputs(url, params);

    if (!params) {
      return url;
    }

    for (let param in params) {
      if (!url.match(this.getPattern(param))) {
        const err =
          "Input syntax error on line: " + "'" + index + "' " +
          "key: " + "'" + key + "'" +
          "value: " + "'" + url + "'" +
          "parameters must contain: " + "'" + this.getPattern(param) + "'";

        throw new Error(err);
      }
      url = url.split(this.getPattern(param)).join(params[param]);
    }

    return this.createFullURL(url);
  }

  getPattern(n: string) {
    n = String(+n + +'1');
    let Pattern = '{param+}';
    Pattern = Pattern.split('+').join(n);
    return Pattern;
  }

  validateInputs(url: any, params: any) {
    let count = url.split(/{param.}/).length - 1;
    let inputParamLenth = params?.length;
    if (count != inputParamLenth) {
      const err =
        'Parameters miss match with the Url links. ' +
        'In Url has ' + count +
        ', but generateUrl() receives ' + inputParamLenth +
        ' parameters.';

      throw new Error(err);
    }
  }

}


export abstract class Logger {

    public static TRACE = 12000;
    public static DEBUG = 10000;
    public static INFO = 8000;
    public static WARN = 6000;
    public static ERROR = 4000;
    public static FATAL = 2000;
    public static OFF = 1000;

    private static LOGLEVEL = Logger.DEBUG;
    private class_data;

    constructor(className: any) {
        this.class_data = className;
    }

    public trace(message: any) {
        if (Logger.TRACE <= Logger.LOGLEVEL) {
            this.lgTrace(this.getPatternParsed('TRACE', this.class_data), message);
        }
    }

    public debug(message: any) {
        if (Logger.DEBUG <= Logger.LOGLEVEL) {
            this.lgDebug(this.getPatternParsed('DEBUG', this.class_data), message);
        }
    }

    public info(message: any) {
        if (Logger.INFO <= Logger.LOGLEVEL) {
            this.lgInfo(this.getPatternParsed('INFO', this.class_data), message);
        }
    }

    public warn(message: any) {
        if (Logger.WARN <= Logger.LOGLEVEL) {
            this.lgWarn(this.getPatternParsed('WARN', this.class_data), message);
        }
    }

    public error(message: any) {
        if (Logger.ERROR <= Logger.LOGLEVEL) {
            this.lgError(this.getPatternParsed('ERROR', this.class_data), message);
        }
    }

    public fatal(message: any) {
        if (Logger.FATAL <= Logger.LOGLEVEL) {
            this.lgFatal(this.getPatternParsed('FATAL', this.class_data), message);
        }
    }

    public static setLevel(level: any) {
        Logger.LOGLEVEL = level;
    }

    private getPatternParsed(level: string, classData: any = {}) {
        //2017-03-25 19:03:54.189 DEBUG 4828 ---
        return `${this.getDate()}  ${level.toUpperCase()} [ ${classData.name}.ts ]`;
    }

    private getDate() {
        let d: any = new Date();
        d = d.getFullYear() + '-'
            + ('0' + (d.getMonth() + 1)).slice(-2) + '-'
            + ('0' + d.getDate()).slice(-2) + ' '
            + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2);
        return d;
    }

    protected abstract lgTrace(info: string, message: any): void;

    protected abstract lgDebug(info: string, message: any): void;

    protected abstract lgInfo(info: string, message: any): void;

    protected abstract lgWarn(info: string, message: any): void;

    protected abstract lgError(info: string, message: any): void;

    protected abstract lgFatal(info: string, message: any): void;

}

export class LoggerFactory {

    static getLogger(className: any): Logger {
        return new ConsoleLogger(className);
    }

}

export class ConsoleLogger extends Logger {

    constructor(className: any) {
        super(className);
    }

    protected lgTrace(info: string, message: any) {
        this.logdata(info, message);
    }

    protected lgDebug(info: string, message: any) {
        this.logdata(info, message);
    }

    protected lgInfo(info: string, message: any) {
        this.logdata(info, message);
    }

    protected lgWarn(info: string, message: any) {
        this.logdata(info, message);
    }

    protected lgError(info: string, message: any) {
        this.logdata(info, message);
    }

    protected lgFatal(info: string, message: any) {
        this.logdata(info, message);
    }

    private logdata(info: string, message: any) {
        if (typeof message == 'boolean' || typeof message == 'number' || typeof message == 'string') {
            console.log(info + ' ' + message);
        } else {
            console.log(info);
            console.log(message);
        }
    }

}
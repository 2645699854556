import { EmbedVideoType } from "./food.model";

export class OptionalVideo {
    private _optionalVideoType: EmbedVideoType;
    private _optionalVideoId: string;

    //custom props
    private _youtubeVideoThumbnail: string;

    constructor(json?: any) {
        this._optionalVideoType = json.optional_video_type;
        this._optionalVideoId = json.optional_video_id
    }

    public get optionalVideoType(): EmbedVideoType {
        return this._optionalVideoType;
    }

    public set optionalVideoType(value: EmbedVideoType) {
        this._optionalVideoType = value;
    }

    public get optionalVideoId(): string {
        return this._optionalVideoId;
    }

    public set optionalVideoId(value: string) {
        this._optionalVideoId = value;
    }

    public get finalUrl(): string {
        let url!: string;

        if (this.optionalVideoType === EmbedVideoType.YTSHORT) {
            url = `https://www.youtube.com/embed/${this.optionalVideoId}?autoplay=1&controls=0&mute=1&loop=1&playlist=${this.optionalVideoId}`;

        } else if (this.optionalVideoType === EmbedVideoType.INSTAREEL) {
            url = `https://www.instagram.com/reel/${this.optionalVideoId}`;

        } else if (this.optionalVideoType === EmbedVideoType.TIKTOK) {
            url = `https://www.tiktok.com/embed/v2/${this.optionalVideoId}`;

        }

        return url;
    }

    public get youtubeVideoThumbnail(): string {
        if (this.optionalVideoId && this.optionalVideoType == EmbedVideoType.YTSHORT) {
            this._youtubeVideoThumbnail = `https://img.youtube.com/vi/${this._optionalVideoId}/maxresdefault.jpg`;
        }
        return this._youtubeVideoThumbnail;
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { bounceInDown, bounceInUp } from '../../animations/animations';
import { FStallService } from '../../services/f-stall.service';
import { ActivatedRoute } from '@angular/router';
import { finalize, map } from 'rxjs';
import { Category } from '../../models/category.model';

@Component({
  selector: 'app-fstall-buttons',
  templateUrl: './fstall-buttons.component.html',
  styleUrls: ['./fstall-buttons.component.scss'],
  animations: [
    bounceInDown
  ]
})
export class FstallButtonsComponent implements OnInit {
  stallId: string;
  categories: Category[];
  selectedCategory: any;
  @Input() isLoaded: boolean = false;
  @Output('selectedCategory') onChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fStallService: FStallService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.stallId = params['stallId'];

      if (this.stallId) {
        this.getStallCategories();
      }
    });

    this.selectedCategory = 'All';
  }

  private getStallCategories(): void {
    this.fStallService.getCategories(this.stallId).pipe(
      finalize(() => { })
    ).subscribe((res: any) => {
      let arr: Category[] = [];
      res.forEach((item: any) => {
        arr.push(new Category(item));
      });

      this.categories = arr;
    });
  }

  getSelectedCategory(data: any): boolean {
    return data === this.selectedCategory;
  }

  onSelectCategory(data: any): void {
    this.selectedCategory = data;
    this.onChanged.emit(this.selectedCategory);
  }

}
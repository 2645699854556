<!-- New Version -->
<div class="page-header" [style.background-color]="bgColor">
    <div class="container">
        <div class="search">
            <div class="input-group border">
                <input type="text" class="form-control form-control-lg shadow-none" placeholder="Search"
                    [(ngModel)]="searchTerm" (keyup)="onSearchEvent()" (keyup.enter)="onSearchEvent()">
                <button class="btn">
                    <ng-container *ngIf="!searchTerm; else clearIcon">
                        <i class='bx bx-search'></i>
                    </ng-container>
                    <ng-template #clearIcon>
                        <i class='bx bx-x' (click)="clearEvent()"></i>
                    </ng-template>
                </button>
            </div>
        </div>

        <div class="filter-btn-container">
            <button type="button" class="btn filter-btn" [ngClass]="{ 'active': toggle }" (click)="onVegOnlyBtnClick()">
                Veg Only
            </button>
        </div>
    </div>
</div>
<button #alertModalBtnRef type="button" class="btn btn-primary alert-trigger-btn" data-bs-toggle="modal"
    data-bs-target="#alertModal">
    Click
</button>

<div class="popup-modal">
    <!-- Modal -->
    <div class="modal fade" id="alertModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header text-danger">
                    {{ error.title }}
                </div>

                <div class="modal-body text-center">
                    <p>
                        {{ error.message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
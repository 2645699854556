import { Component, Input, OnInit } from '@angular/core';
import { bounceInUp } from '../../animations/animations';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
  selector: 'app-chat-btn',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    bounceInUp
  ]
})
export class ChatComponent implements OnInit {
  @Input() timeout: number = 1500;
  qParams: any;
  isLoaded: boolean = false;

  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('call-for-service')) {
          this.isLoaded = false;
          this.qParams = null;
        } else {
          this.getSessionData();
        }
      }
    });
  }

  goToChatPage(): void {
    if (this.qParams) {
      this.router.navigate(
        ['/call-for-service'],
        { queryParams: this.qParams }
      )
    }
  }

  private getSessionData(): void {
    setTimeout(() => {
      const sessionData = this.storageService.getSessionData('stallData');
      if (sessionData) {
        this.qParams = {
          id: sessionData.stall,
          v: sessionData.v,
          table: sessionData.table
        }
        this.isLoaded = true;
      }
    }, this.timeout);
  }
}

import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from './layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import * as Hammer from 'hammerjs';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LoaderModule } from './library/loader/loader.module';
import { ToastrModule } from 'ngx-toastr';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL }
  };
}

declare global {
  interface Window {
    instgrm: any;
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HammerModule,
    CoreModule,
    SharedModule,
    LoaderModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
  selector: 'app-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.scss']
})
export class InvalidLinkComponent implements OnInit {
  error: any;
  @ViewChild('alertModalBtnRef', { static: false }) alertModalBtnRef: ElementRef<HTMLButtonElement>;

  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.storageService.clearSessionData('stallData');
    this.error = {
      key: "QR_EXPIRED",
      title: "Invalid Link!",
      message: "This link is no longer in use."
    }

    this.showErrorPopup();
  }

  private showErrorPopup(): void {
    setTimeout(() => {
      if (this.alertModalBtnRef) {
        this.alertModalBtnRef.nativeElement.click();
      }
    }, 200);
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { TableService } from 'src/app/shared/services/table.service';
import { StorageService } from '../services/storage/storage.service';
import { FStallService } from 'src/app/shared/services/f-stall.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  stallId: any;
  qrVersion: any;
  tableId: any;
  active: boolean = false;

  constructor(
    private tableService: TableService,
    private router: Router,
    private storageService: StorageService,
    private fStallService: FStallService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.stallId = route.paramMap.get('stallId');
    this.qrVersion = route.paramMap.get('qrVersion');
    this.tableId = route.paramMap.get('tableId');

    if (route.queryParamMap.get('v') && route.queryParamMap.get('table')) {
      this.qrVersion = route.queryParamMap.get('v');
      this.tableId = route.queryParamMap.get('table');
    }

    if (this.stallId && this.qrVersion && this.tableId) {
      return this.tableService.getAllTables(this.stallId, this.qrVersion, this.tableId).pipe(
        map((res: any) => {
          this.setSessionData();
          return true;
        }),
        catchError((err) => {
          this.router.navigate(['/invalid-link']);
          return of(false);
        })
      );
    } else if (this.stallId) {
      this.storageService.clearSessionData('stallData');
      return this.fStallService.getFoodStallDetails(this.stallId).pipe(
        map((res: any) => {
          if (Object.keys(res).length === 0) {
            this.router.navigate(['/invalid-link']);
            return false;
          } else {
            return true;
          }
        })
      );
    }

    return true;
  }

  private setSessionData(): void {
    if (this.stallId && this.qrVersion && this.tableId) {
      const stallData = {
        stall: this.stallId,
        v: this.qrVersion,
        table: this.tableId
      }
      this.storageService.setSessionData('stallData', stallData);
    }
  }

}

import { Injectable } from '@angular/core';
import { Logger, LoggerFactory } from '../../utilities/logger/logger';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private log: Logger = LoggerFactory.getLogger(PusherService);
  private pusher: Pusher;
  private channel: any;
  private configs: any = environment.pusherConfig;

  constructor() {
    this.pusher = new Pusher(this.configs.key, {
      cluster: this.configs.cluster
    });
    this.channel = this.pusher.subscribe('foodie-bell');
  }

  requestPermission(): Promise<void> {
    return new Promise((resolve, reject) => {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            this.log.info('Notification permission granted.');
            resolve();
          } else {
            this.log.info('Notification permission dismissed by the user.');
            reject(new Error('Notification permission denied by the user.'));
          }
        })
    });
  }

  subscribeToChannel(deviceId: any, callback: (data: any) => void): void {
    this.log.info('Subscribing to notifications...');
    this.channel.bind(deviceId.toString(), (data: any) => {
      callback(data);
    });
  }


  unsubscribeFromChannel(): void {
    this.log.info('Unsubscribing from notifications...');
    this.channel.unbind();
  }
}

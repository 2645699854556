import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, forkJoin } from 'rxjs';
import { LoaderService } from 'src/app/library/loader/loader.service';
import { bounceInDown, bounceInUp, fadeInOut } from 'src/app/shared/animations/animations';
import { FoodStall } from 'src/app/shared/models/food-stall.model';
import { Food, FoodType } from 'src/app/shared/models/food.model';
import { FStallService } from 'src/app/shared/services/f-stall.service';

@Component({
  selector: 'app-fstall-dashboard',
  templateUrl: './fstall-dashboard.component.html',
  styleUrls: ['./fstall-dashboard.component.scss'],
  animations: [
    fadeInOut,
    bounceInUp,
    bounceInDown
  ]
})
export class FstallDashboardComponent implements OnInit {
  isLoaded: boolean = false;
  stallId: string;
  response: any;
  currentPage: number = 1;
  foodStall: FoodStall;
  bgColor: string = '#FFFFFF';
  selectedCategory: any;
  foodItems: Food[];
  isVegOnlyEnabled!: boolean;
  foodType: typeof FoodType = FoodType;
  searchTerm: string;
  fullyLoaded: boolean = false;
  autoplayIndex: number | null;
  scrollDistance: number = 2;
  scrollThrottle: number = 50;
  interval: any = null;

  @ViewChild('splashScreen') splashScreen: ElementRef;

  constructor(
    private fStallService: FStallService,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.stallId = params['stallId'];

      if (this.stallId) {
        this.loader.show();
        forkJoin([
          this.getStallDetails(),
          this.getFoodList
        ]);
      }
    });
  }

  onImageLoad(isLoaded: any): void {
    this.fullyLoaded = isLoaded;
    // if (this.fullyLoaded) {
    //   this.loader.hide();
    //   setTimeout(() => {
    //     this.isLoaded = true;
    //     this.fullyLoaded = false;

    //     setTimeout(() => {
    //       this.getAutoplayIndex();
    //       this.splashScreen.nativeElement.remove();
    //     }, 800)
    //   }, 3000);
    // }
  }

  private getStallDetails(): void {
    this.fStallService.getFoodStallDetails(this.stallId).subscribe(
      (res) => {
        if (Object.keys(res).length !== 0) {
          this.foodStall = new FoodStall(res);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private get getFoodList(): Food[] {
    this.fStallService.getFoodItems({ pageNo: this.currentPage, id: this.stallId }).pipe(
      finalize(() => {
        this.isLoaded = true;

        if (this.isLoaded && this.loader.active) {
          this.loader.hide();
        }
      })
    ).subscribe((res: any) => {
      this.response = res;
      let arr: Food[] = [];
      res.data.forEach((item: any) => {
        arr.push(new Food(item));
      });

      this.foodItems = arr;
      this.autoplayIndex = 0;

      this.getAutoplayIndex();
    });

    return this.foodItems;
  }

  onCategorySelect(category: any): void {
    this.selectedCategory = category;
    this.currentPage = 1;
    this.loader.show('Loading...');

    if (this.searchTerm) {
      if (this.isVegOnlyEnabled) {
        if (this.selectedCategory === 'All') {
          this.filterFoodItems({ searchTerm: this.searchTerm, type: this.foodType.VEG });
        } else {
          this.filterFoodItems({
            searchTerm: this.searchTerm,
            type: this.foodType.VEG,
            category: this.selectedCategory.id
          });
        }
      } else {
        if (this.selectedCategory === 'All') {
          this.filterFoodItems({ searchTerm: this.searchTerm });
        } else {
          this.filterFoodItems({ searchTerm: this.searchTerm, category: this.selectedCategory.id });
        }
      }
    } else {
      if (this.isVegOnlyEnabled) {
        if (this.selectedCategory === 'All') {
          this.filterFoodItems({ type: this.foodType.VEG });
        } else {
          this.filterFoodItems({ type: this.foodType.VEG, category: this.selectedCategory.id });
        }
      } else {
        if (this.selectedCategory === 'All') {
          this.foodItems = this.getFoodList;
        } else {
          this.filterFoodItems({ category: this.selectedCategory.id });
        }
      }
    }

    this.getAutoplayIndex();
  }

  onVegOnlyBtnClick(isVegOnly: boolean): void {
    this.isVegOnlyEnabled = isVegOnly;
    this.currentPage = 1;
    this.loader.show('Loading...');

    if (this.isVegOnlyEnabled) {
      if (this.selectedCategory && this.selectedCategory.id !== 'All') {
        this.filterFoodItems({ type: this.foodType.VEG, category: this.selectedCategory.id });
      } else {
        this.filterFoodItems({ type: this.foodType.VEG });
      }
    } else {
      if (this.selectedCategory === 'All' || !this.selectedCategory) {
        this.foodItems = this.getFoodList;
      } else {
        this.filterFoodItems({ category: this.selectedCategory.id });
      }
    }

    this.getAutoplayIndex();
  }

  onSearch(term: string): void {
    this.searchTerm = term;
    this.currentPage = 1;
    this.loader.show('Loading...');

    if (this.searchTerm) {
      if (this.isVegOnlyEnabled) {
        if (this.selectedCategory && this.selectedCategory.id !== 'All') {
          this.filterFoodItems({
            searchTerm: this.searchTerm,
            type: this.foodType.VEG,
            category: this.selectedCategory.id
          });
        } else {
          this.filterFoodItems({ searchTerm: this.searchTerm, type: this.foodType.VEG });
        }
      } else {
        if (this.selectedCategory === 'All' || !this.selectedCategory) {
          this.filterFoodItems({ searchTerm: this.searchTerm });
        } else {
          this.filterFoodItems({ searchTerm: this.searchTerm, category: this.selectedCategory.id });
        }
      }
    } else {
      if (this.isVegOnlyEnabled) {
        if (this.selectedCategory && this.selectedCategory.id !== 'All') {
          this.filterFoodItems({
            type: this.foodType.VEG,
            category: this.selectedCategory.id
          });
        } else {
          this.filterFoodItems({ type: this.foodType.VEG });
        }
      } else {
        if (this.selectedCategory === 'All' || !this.selectedCategory) {
          this.foodItems = this.getFoodList;
        } else {
          this.filterFoodItems({ category: this.selectedCategory.id });
        }
      }
    }

    this.getAutoplayIndex();
  }

  private filterFoodItems(filters: any, append?: boolean) {
    if (this.searchTerm) {
      if (filters.category && filters.type) {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          searchTerm: filters.searchTerm,
          type: filters.type,
          category: filters.category,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });

      } else if (filters.category) {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          searchTerm: filters.searchTerm,
          category: filters.category,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });

      } else if (filters.type) {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          searchTerm: filters.searchTerm,
          type: filters.type,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });
      } else {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          searchTerm: filters.searchTerm,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });
      }

    } else {
      if (filters.category && filters.type) {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          type: filters.type,
          category: filters.category,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });

      } else if (filters.category) {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          category: filters.category,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });

      } else if (filters.type) {
        this.fStallService.filterFoodItems({
          stallId: this.stallId,
          type: filters.type,
          pageNo: this.currentPage
        }).pipe(
          finalize(() => {
            if (this.loader.active) {
              this.loader.hide();
            }
          })
        ).subscribe((res: any) => {
          this.response = res;
          let arr: Food[] = [];
          res.data.forEach((item: any) => {
            arr.push(new Food(item));
          });

          if (append) {
            this.foodItems = [...this.foodItems, ...arr];
          } else {
            this.foodItems = arr;
            this.autoplayIndex = 0;
          }
        });
      }
    }
  }

  private getNextPageFoodList(): void {
    this.fStallService.getNextPageFoodItems({ pageNo: this.currentPage, id: this.stallId }).pipe(
      finalize(() => { }))
      .subscribe((res: any) => {
        this.response = res;
        let arr: Food[] = [];
        res.data.forEach((item: any) => {
          arr.push(new Food(item));
        });

        this.foodItems = [...this.foodItems, ...arr];
      });
  }

  onScrollDown(): void {
    this.getNextPageFoodItems(this.currentPage + 1);
  }

  getNextPageFoodItems(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.response.last_page) {
      this.currentPage = pageNumber;

      if (this.searchTerm && (this.selectedCategory && this.selectedCategory !== 'All') && this.isVegOnlyEnabled) {
        this.filterFoodItems({
          searchTerm: this.searchTerm,
          type: this.foodType.VEG,
          category: this.selectedCategory.id
        }, true);

      } else if (this.searchTerm && (this.selectedCategory && this.selectedCategory !== 'All')) {
        this.filterFoodItems({
          searchTerm: this.searchTerm,
          category: this.selectedCategory.id
        }, true);

      } else if (this.searchTerm && this.isVegOnlyEnabled) {
        this.filterFoodItems({
          searchTerm: this.searchTerm,
          type: this.foodType.VEG
        }, true);

      } else if (this.searchTerm) {
        this.filterFoodItems({
          searchTerm: this.searchTerm
        }, true);

      } else if ((this.selectedCategory && this.selectedCategory !== 'All') && this.isVegOnlyEnabled) {
        this.filterFoodItems({
          type: this.foodType.VEG,
          category: this.selectedCategory.id
        }, true);

      } else if (this.selectedCategory && this.selectedCategory !== 'All') {
        this.filterFoodItems({
          category: this.selectedCategory.id
        }, true);

      } else if (!this.selectedCategory || this.selectedCategory === 'All') {
        this.getNextPageFoodList();

      } else if (this.isVegOnlyEnabled) {
        this.filterFoodItems({
          type: this.foodType.VEG
        }, true);
      }
    }
  }

  private generateAutoplaySequence(limit: number): number[] {
    const sequence: number[] = [1];
    let i = 0;

    if (limit <= 3) {
      return [1];
    }

    while (sequence[sequence.length - 1] < limit) {
      if (i % 2 === 0) {
        sequence.push(sequence[sequence.length - 1] + 3);
      } else {
        sequence.push(sequence[sequence.length - 1] + 1);
      }

      i++;
    }

    return sequence;
  }

  private getAutoplayIndex(): void {
    let autoplayInterval: number = 8500;
    clearInterval(this.interval);

    if (this.autoplayIndex !== null) {
      let i = 0;
      this.interval = setInterval(() => {
        const autoplaySequence = this.generateAutoplaySequence(this.foodItems.length);

        if (this.autoplayIndex !== null &&
          this.autoplayIndex !== (autoplaySequence[autoplaySequence.length - 1] - 1)) {
          const newIndex = autoplaySequence[i + 1] - 1;
          if (this.foodItems.indexOf(this.foodItems[newIndex]) === -1) {
            i = 0;
            this.autoplayIndex = 0;
            autoplayInterval = 0;
          } else {
            this.autoplayIndex = newIndex;
            i++;
          }
        } else {
          i = 0;
          this.autoplayIndex = 0;
          autoplayInterval = 0;
        }
      }, autoplayInterval);
    }
  }
}

export const defaultLogoUrl: string = './assets/images/call_for_service/Bell.png';

export const defaultBgColor: string = '#e8e8e8';

export const tagList: any[] = [
    {
        name: "Trending Now",
        value: "TRENDING_NOW",
        imageUrl: "./assets/images/tags/trending_now.svg"
    },
    {
        name: "Top Picks",
        value: "TOP_PICKS",
        imageUrl: "./assets/images/tags/top_picks.svg"
    },
    {
        name: "Offers",
        value: "OFFERS",
        imageUrl: "./assets/images/tags/offers.svg"
    }
];
import { Injectable } from '@angular/core';
import { getServiceUrl } from 'src/environments/environment';

@Injectable()
export class StorageService {

  static DEFAULT_TOKEN_KEY = 'Token';
  static NOTIFICATION_TOKEN_KEY = 'NotificationToken';
  static REFRESH_TOKEN_KEY = "REFRESH_TOKEN";
  static USER = 'USER';

  constructor() { }

  public static getAuthorizationToken(): string | null {
    return StorageService.getStringData(StorageService.DEFAULT_TOKEN_KEY);
  }

  public static getRefreshToken(): string | null {
    return StorageService.getStringData(StorageService.REFRESH_TOKEN_KEY);
  }

  public getNotificationToken(): string | null {
    return StorageService.getStringData(StorageService.NOTIFICATION_TOKEN_KEY);
  }

  public static getItem(key: string): any {
    let data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    }
    return null;
  }

  public static setItem(key: string, value: any): void {
    let val = value;
    if (typeof value == 'object' || typeof value == 'number') {
      val = JSON.stringify(value);
    }

    localStorage.setItem(key, val);
  }

  isSignedIn() {
    return this.getData('isSigned');
  }

  setSignedIn(isTrue: boolean) {
    if (!isTrue) {
      this.clearAll();
    }
    this.setData('isSigned', isTrue);
  }

  saveToken(key: string, token: string) {
    this.setData(key, token);
  }

  setTimeoutValue(key: string, value: any, time_in_seconds: number) {
    this.clearExpiredCache(); // Remove all expired cache before entering new records.
    let expire = ((new Date()).getTime()) + (time_in_seconds * 1000);
    let objx = {
      expire: expire,
      value: value
    };

    this.setData(key, objx);
  }

  getTimeoutValue(key: string) {
    return this.isExpired(key) ? this.getData(key).value : null;
  }

  setData(key: string, value: any) {
    let val = value;
    if (typeof value == 'object' || typeof value == 'number') {
      val = JSON.stringify(value);
    }

    localStorage.setItem(key, val);
  }

  getData(key: string) {
    let data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    }

    return null;
  }

  setSessionData(key: string, value: any) {
    let val = value;
    if (typeof value == 'object' || typeof value == 'number') {
      val = JSON.stringify(value);
    }

    sessionStorage.setItem(key, val);
  }

  getSessionData(key: string) {
    let data = sessionStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    }

    return null;
  }

  clearSessionData(key: string) {
    let data = sessionStorage.getItem(key);
    if (data) {
      sessionStorage.removeItem(key);
    }
  }

  exists(key: string) {
    return localStorage.getItem(key) != null;
  }

  clearCache() {
    const arr = this.getAvailableCacheKeys();

    for (let i = 0; i < arr.length; i++) {
      localStorage.removeItem(arr[i]);
    }
  }

  clearAll() {
    console.log('clearAll');
    localStorage.clear();
  }

  clearNotificationToken() {
    localStorage.removeItem(StorageService.NOTIFICATION_TOKEN_KEY);
  }

  private static getStringData(key: string): string | null {
    return localStorage.getItem(key);
  }

  private isExpired(key: string) {
    return (this.exists(key) && this.getData(key).expire >= ((new Date()).getTime()));
  }

  private clearExpiredCache() {
    // This function will remove all expired cache data.
    const arr = this.getAvailableCacheKeys();

    // Iterate over arr and remove the items which are expired.
    for (let i = 0; i < arr.length; i++) {
      if (!this.isExpired(arr[i])) {
        localStorage.removeItem(arr[i]);
      }
    }
  }

  private getAvailableCacheKeys(): string[] {
    let arr: any[] = [];
    let serviceUrl = getServiceUrl(); // The key we interested.
    let serviceUrlSize = serviceUrl.length;

    // Iterate over localStorage and insert the keys that meet the pattern into arr.
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)?.substring(0, serviceUrlSize) == serviceUrl) {
        arr.push(localStorage.key(i));
      }
    }

    return arr;
  }

}
